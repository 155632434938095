import Bugsnag from 'utils/bugsnag'
import { makeManagementRolesSelector } from 'account/components/userManagement/roles/selectors'
import ApiClient from 'api-client/ApiClient'
import createCRUDActions from 'core/helpers/createCRUDActions'
import { ActionDataKeys } from 'k8s/DataKeys'

const { keystone } = ApiClient.getInstance()

export const mngmRoleActions = createCRUDActions(ActionDataKeys.ManagementRoles, {
  listFn: async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get management roles')
    return keystone.getRoles()
  },
  entityName: 'Role',
  selectorCreator: makeManagementRolesSelector,
})
