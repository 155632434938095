import makeStyles from '@material-ui/styles/makeStyles'
import { listTenants, listUserTenants } from 'account/components/userManagement/tenants/new-actions'
import { tenantsSelector } from 'account/components/userManagement/tenants/selectors'
import {
  listUserRoleAssignments,
  listUsers,
  updateUser,
} from 'account/components/userManagement/users/new-actions'
import { userRolesSelector, usersSelector } from 'account/components/userManagement/users/selectors'
import TenantRolesTableField from 'account/components/userManagement/users/TenantRolesTableField'
import UserPasswordField from 'account/components/userManagement/users/UserPasswordField'
import { setActiveRegion } from 'api-client/helpers'
import clsx from 'clsx'
import FormWrapper from 'core/components/FormWrapper'
import Progress from 'core/components/progress/Progress'
import SimpleLink from 'core/components/SimpleLink'
import TextField from 'core/components/validatedForm/TextField'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import Wizard from 'core/components/wizard/Wizard'
import WizardStep from 'core/components/wizard/WizardStep'
import Input from 'core/elements/input/Input'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import useToggler from 'core/hooks/useToggler'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { sessionActions, sessionStoreKey } from 'core/session/sessionReducers'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { requiredValidator } from 'core/utils/fieldValidators'
import { routes } from 'core/utils/routes'
import { prop, propEq } from 'ramda'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useReactRouter from 'use-react-router'
import { emptyObj, pathStr } from 'utils/fp'

const wizardMetaFormattedNames = {
  username: 'Email',
  displayname: 'Display Name',
  roleAssignments: 'Role Assignments',
}

const wizardMetaCalloutFields = Object.keys(wizardMetaFormattedNames)

const listUrl = routes.userManagement.root.path()

const useStyles = makeStyles((theme) => ({
  togglableFieldContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    '& .Mui-disabled': {
      color: theme.palette.text.primary,
    },
  },
  togglableField: {
    width: '50%',
  },
  togglableFieldBtn: {
    marginLeft: theme.spacing(2),
  },
}))

const TogglableTextField = ({
  id,
  label,
  initialValue,
  value,
  required = false,
  TextFieldComponent = TextField,
}) => {
  const classes = useStyles()
  const [showingField, toggleField] = useToggler()
  return (
    <div className={clsx('togglableFieldContainer', classes.togglableFieldContainer)}>
      {showingField ? (
        <TextFieldComponent id={id} label={label} value={value} required={required} />
      ) : (
        <Input className={classes.togglableField} label={label} value={initialValue} disabled />
      )}
      <SimpleLink className={classes.togglableFieldBtn} onClick={toggleField} label={label}>
        {showingField ? 'Cancel' : 'Change'}
      </SimpleLink>
    </div>
  )
}

const tenantRolesValidations = [requiredValidator.withMessage('Must select at least one tenant')]

const EditUserPage = () => {
  const { match, history } = useReactRouter()
  const dispatch = useDispatch()
  const userId = match.params.id
  const { loading: loadingUsers } = useListAction(listUsers)
  const users = useSelector(usersSelector)
  const user = useMemo(() => users.find(propEq('id', userId)) || emptyObj, [users, userId])

  const { update, updating, error: errorUpdatingUser } = useUpdateAction(updateUser)

  const { loading: loadingTenants } = useListAction(listTenants)
  const tenants = useSelector(tenantsSelector)

  // Fixme
  // Had to add this bc it was never getting called otherwise
  useEffect(() => {
    listUserRoleAssignments.call({ userId })
  }, [])

  const { loading: loadingRoleAssignments } = useListAction(listUserRoleAssignments, {
    params: { userId },
    initialLoadingState: true,
  })
  const roleAssignments = useSelectorWithParams(userRolesSelector, { userId })
  const { reload: reloadUserTenants } = useListAction(listUserTenants)

  const session = useSelector(prop(sessionStoreKey))
  const { userDetails } = session
  const { id: activeUserId, email: activeUserEmail } = userDetails
  const { prefs, updatePrefs } = useScopedPreferences()
  const [oldUserPrefs, setOldUserPrefs] = useState(prefs)
  const [activeUserUpdated, setActiveUserUpdated] = useState(false)

  const loadingSomething = loadingUsers || loadingTenants || loadingRoleAssignments || updating
  const isActiveUser = userId === activeUserId
  const showPasswordField = !isActiveUser

  const initialContext = useMemo(
    () => ({
      id: userId,
      username: user.username || user.email,
      displayname: user.displayname || '',
      roleAssignments: roleAssignments.reduce(
        (acc, roleAssignment) => ({
          ...acc,
          [pathStr('scope.project.id', roleAssignment)]: pathStr('role.id', roleAssignment),
        }),
        {},
      ),
    }),
    [user, roleAssignments],
  )

  useEffect(() => {
    // When the active user's email gets updated, transfer their old prefs over to the new email
    if (activeUserUpdated) {
      updatePrefs(oldUserPrefs)
      if (oldUserPrefs.currentRegion) {
        setActiveRegion(oldUserPrefs.currentRegion)
      }
      history.push(listUrl)
    }
  }, [activeUserEmail])

  const handleUserUpdate = async (values) => {
    const { success, response: updatedUser } = await update(values)
    if (!success) return
    if (isActiveUser) {
      setActiveUserUpdated(true)
      dispatch(
        sessionActions.updateSession({
          username: updatedUser.email,
          userDetails: {
            ...userDetails,
            username: updatedUser.email,
            name: updatedUser.email,
            email: updatedUser.email,
            displayName: updatedUser.displayname, // displayName is a UI variable
            displayname: updatedUser.displayname, // displayname is what we get from the api
          },
        }),
      )
      reloadUserTenants(true)
    } else {
      history.push(listUrl)
    }
  }

  return (
    <FormWrapper
      title={`Edit User ${user.displayname || user.username || ''}`}
      loading={loadingSomething}
      renderContentOnMount={!loadingSomething}
      message={updating ? 'Submitting form...' : 'Loading User...'}
      backUrl={listUrl}
    >
      <Wizard onComplete={handleUserUpdate} context={initialContext} error={errorUpdatingUser}>
        {({ wizardContext, setWizardContext, onNext }) => (
          <>
            <WizardStep
              stepId="basic"
              label="Basic Info"
              summaryTitle={`Edit User ${user.displayname || user.username || ''}`}
              summaryKeyOverrides={wizardMetaFormattedNames}
              summaryCalloutFields={wizardMetaCalloutFields}
            >
              <ValidatedForm
                initialValues={wizardContext}
                onSubmit={setWizardContext}
                triggerSubmit={onNext}
              >
                {({ values }) => (
                  <>
                    <TogglableTextField
                      id="username"
                      label="Username or Email"
                      initialValue={user.username}
                      required
                    />
                    <TogglableTextField
                      id="displayname"
                      label="Display Name"
                      initialValue={user.displayname}
                    />
                    {showPasswordField && (
                      <TogglableTextField
                        id="password"
                        label="Password"
                        initialValue={'********'}
                        value={values.password}
                        TextFieldComponent={UserPasswordField}
                      />
                    )}
                  </>
                )}
              </ValidatedForm>
            </WizardStep>
            <WizardStep stepId="tenants" label="Tenants and Roles">
              <ValidatedForm
                fullWidth
                initialValues={wizardContext}
                onSubmit={setWizardContext}
                triggerSubmit={onNext}
              >
                <Progress
                  renderContentOnMount={!loadingTenants}
                  loading={loadingTenants}
                  message={'Loading Tenants...'}
                >
                  <TenantRolesTableField
                    validations={tenantRolesValidations}
                    id="roleAssignments"
                    tenants={tenants}
                  />
                </Progress>
              </ValidatedForm>
            </WizardStep>
          </>
        )}
      </Wizard>
    </FormWrapper>
  )
}

export default EditUserPage
