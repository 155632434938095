import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { CustomerTiers, CustomerTierLabels, planComparisonLink, upgradeLinks } from 'app/constants'
import Text from 'core/elements/Text'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import ExternalLink from 'core/components/ExternalLink'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import Button from 'core/elements/button'
import { trackUpgrade } from 'utils/tracking'
import clsx from 'clsx'
import Card from 'core/elements/card'

const useStyles = makeStyles((theme: Theme) => ({
  planHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  primaryColorText: {
    color: theme.palette.primary.main,
  },
  currentPlan: {
    padding: theme.spacing(3, 4), // do we want to just do numbers or use theme.spacing?
  },
  plan: {
    margin: theme.spacing(1, 0),
  },
  upgradePlans: {
    display: 'grid',
    gridTemplateColumns: '1fr 1px 1fr',
  },
  singleUpgradePlan: {
    gridTemplateColumns: '1fr !important',
  },
  upgradePlan: {
    padding: theme.spacing(2, 4, 4),
  },
  border: {
    background: theme.components.card.border,
  },
  bullets: {
    color: theme.components.card.passiveText,
  },
  [CustomerTiers.Enterprise]: {
    color: theme.palette.pink.main,
  },
  [CustomerTiers.Growth]: {
    color: theme.palette.yellow.main,
  },
  [CustomerTiers.Freedom]: {
    color: theme.palette.blue.main,
  },
}))

const planDescriptions = {
  [CustomerTiers.Enterprise]: 'For organizations with large & complex infrastructure',
  [CustomerTiers.Growth]: 'For teams running production workloads',
  [CustomerTiers.Freedom]: 'For teams getting started with Kubernetes',
}

const showGrowthUpgrade = (plan) => plan === CustomerTiers.Freedom

const showEnterpriseUpgrade = (plan) => [CustomerTiers.Freedom, CustomerTiers.Growth].includes(plan)

const isGrowthOrHigher = (plan) =>
  [CustomerTiers.Enterprise, CustomerTiers.Growth, CustomerTiers.OEM].includes(plan)

const isEnterpriseOrHigher = (plan) => [CustomerTiers.Enterprise, CustomerTiers.OEM].includes(plan)

const CurrentPlanCard = () => {
  const classes = useStyles({})
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { features, userDetails } = session
  const { username } = userDetails

  return (
    <Card title={null} withCustomBody>
      <div className={classes.currentPlan}>
        <div className={classes.planHeader}>
          <Text variant="caption1">My Current Plan</Text>
          <ExternalLink url={planComparisonLink}>
            <Text variant="caption1" className={classes.primaryColorText}>
              See Detailed Comparison{' '}
              <FontAwesomeIcon solid className={classes.primaryColorText}>
                arrow-right
              </FontAwesomeIcon>
            </Text>
          </ExternalLink>
        </div>
        {features && (
          <>
            <Text variant="h3" className={clsx(classes.plan, classes[features?.customer_tier])}>
              {CustomerTierLabels[features?.customer_tier]}
            </Text>
            <Text variant="body2">{planDescriptions[features?.customer_tier]}</Text>
          </>
        )}
      </div>
      <div
        className={clsx({
          [classes.upgradePlans]: true,
          [classes.singleUpgradePlan]: features?.customer_tier === CustomerTiers.Growth,
        })}
      >
        {showGrowthUpgrade(features?.customer_tier) && (
          <>
            <div className={classes.upgradePlan}>
              <Text variant="h4">Growth</Text>
              <Text variant="body2">{planDescriptions[CustomerTiers.Growth]}</Text>
              <ul className={classes.bullets}>
                <li>
                  <Text variant="body2" className={classes.bullets}>
                    From 100 to 2000 vCPUs
                  </Text>
                </li>
                <li>
                  <Text variant="body2" className={classes.bullets}>
                    Monitoring & remediation
                  </Text>
                </li>
                <li>
                  <Text variant="body2" className={classes.bullets}>
                    Access to support & 99.9% SLA
                  </Text>
                </li>
                <li>
                  <Text variant="body2" className={classes.bullets}>
                    Up to 3 regions
                  </Text>
                </li>
              </ul>
              <ExternalLink
                url={
                  isGrowthOrHigher(features?.customer_tier)
                    ? ''
                    : upgradeLinks[CustomerTiers.Freedom]
                }
              >
                <Button
                  disabled={isGrowthOrHigher(features?.customer_tier)}
                  variant="cta"
                  onClick={() => trackUpgrade({ username, customerTier: CustomerTiers.Growth })}
                >
                  Upgrade to Growth
                </Button>
              </ExternalLink>
            </div>
            <div className={classes.border} />
          </>
        )}
        {showEnterpriseUpgrade(features?.customer_tier) && (
          <div className={classes.upgradePlan}>
            <Text variant="h4">Enterprise</Text>
            <Text variant="body2">{planDescriptions[CustomerTiers.Enterprise]}</Text>
            <ul className={classes.bullets}>
              <li>
                <Text variant="body2" className={classes.bullets}>
                  Tailored support with a designated account and customer success team
                </Text>
              </li>
              <li>
                <Text variant="body2" className={classes.bullets}>
                  Multiple regions
                </Text>
              </li>
              <li>
                <Text variant="body2" className={classes.bullets}>
                  SSO
                </Text>
              </li>
              <li>
                <Text variant="body2" className={classes.bullets}>
                  Advanced Integrations
                </Text>
              </li>
            </ul>
            <ExternalLink
              url={
                isEnterpriseOrHigher(features?.customer_tier)
                  ? ''
                  : upgradeLinks[CustomerTiers.Growth]
              }
            >
              <Button
                disabled={isEnterpriseOrHigher(features?.customer_tier)}
                variant="cta"
                onClick={() => trackUpgrade({ username, customerTier: CustomerTiers.Enterprise })}
              >
                Upgrade to Enterprise
              </Button>
            </ExternalLink>
          </div>
        )}
      </div>
    </Card>
  )
}

export default CurrentPlanCard
